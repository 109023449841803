@tailwind base;
@tailwind components;
@tailwind utilities;
@import url('https://fonts.googleapis.com/css2?family=Heebo:wght@200;300;400;600&display=swap');

html{
  scroll-behavior: smooth;
}

body {
  margin: 0;
  font-family:  'Heebo', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #f1f1f1;

}

section{
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 10vh 0 ;
  text-align: center;
}

section.alternative{
  background-color: #e9e8e8!important;

}

.hero{
  background:linear-gradient(0deg, rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0.7)), url('/src/img/traquair.jpg');
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center center;
}
.hero2{
  background:linear-gradient(0deg, rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0.7)), url('/src/img/traquair2.jpg');
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center center;
}

